<template>
    <div>
      <v-data-table 
        v-bind="$attrs"        
        :headers="showHeaders"        
        hide-default-footer         
        loading-text="กำลังโหลด... โปรดรอสักครู่"
        v-on="$listeners"        
        :items="items"
        :items-per-page="itemsPerPage"
        :options="pagination"
      >
        <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"><slot :name="slot" v-bind="scope"/></template>        
      </v-data-table>           

      <div class="d-flex align-center flex-column flex-sm-row">
        <v-pagination          
          :length="!!items ? Math.ceil(items.length / itemsPerPage) : 1"
          v-model="pagination.page"
          :total-visible="9"
        ></v-pagination>
        <div>ค้นหา {{ pagination.totalData }} จากทั้งหมด {{ pagination.totalRecords }} รายการ</div>
        <div class="d-flex align-center ml-sm-auto">
          จำนวนแถวต่อหน้า 
          <v-select
            class="ml-4"
            style="max-width:75px;"
            hide-details            
            solo
            dense
            :items="[10,20,50,100,200]"
            v-model="itemsPerPage"            
          ></v-select>
        </div>
        <v-menu
          v-model="tableSheet"
          :close-on-content-click="false"
          :nudge-width="200"          
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn              
              v-bind="attrs"
              v-on="on"     
              icon                
            ><v-icon>{{ mdiTableCog }}</v-icon></v-btn>
          </template>

          <v-card class="pa-2">
            <v-checkbox 
                v-for="(header, header_index) in $attrs.headers" 
                :key="'header_' + header_index" 
                :label="header.text"
                :value="header"
                v-model="selectedHeaders"
              ></v-checkbox>
          </v-card>
        </v-menu>
        <!--
        <v-bottom-sheet
          v-model="tableSheet"
          inset
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn              
              v-bind="attrs"
              v-on="on"     
              icon                
            ><v-icon>{{ mdiTableCog }}</v-icon></v-btn>
          </template>
          <v-sheet
            class="text-center pa-4"            
          >
            <v-btn
              class="mt-2"
              text
              color="error"
              @click="tableSheet = !tableSheet"
            >
              close
            </v-btn>
            <div class="my-3">
              <v-checkbox 
                v-for="(header, header_index) in $attrs.headers" 
                :key="'header_' + header_index" 
                :label="header.text"
                :value="header"
                v-model="selectedHeaders"
              ></v-checkbox>
            </div>
          </v-sheet>
        </v-bottom-sheet>
        -->
      </div> 
    </div>    
</template>
<script>
import { mdiTableCog } from '@mdi/js'
export default {
  props: {
    options: {},
    items: {},
  },
  data() {
    return {
      itemsPerPage: 10,
      pagination: {
        totalData: null,
        totalRecords: null,
        pageCount: null,
      },
      tableSheet: false,
      selectedHeaders: [],
      mdiTableCog,
    }
  },
  watch: {
    selectedHeaders: {
      handler() {
        if (this.isSaveState) this.saveState()
      },
      deep: true,
    },
    items(value) {
      console.log(value)
      if (!value) {
        this.pagination = {
          totalData: 0,
          totalRecords: 0,
          pageCount: 0,
        }

        return
      }

      // TODO : Filter count in total record and total data

      this.pagination = {
        page: 1,
        totalData: value.length,
        totalRecords: value.length,
        pageCount: Math.ceil(value.length / this.itemsPerPage),
      }
    },
  },
  created() {
    if (!this.isSaveState) this.selectedHeaders = this.$attrs.headers
  },
  computed: {
    showHeaders() {
      return this.$attrs.headers.filter(header => {
        for (let i = 0; i < this.selectedHeaders.length; i++) {
          const selectedHeader = this.selectedHeaders[i]

          if (selectedHeader.value == header.value) return true
        }
        return false
      })
    },
  },
  methods: {
    updateSortBy(payload) {},
    updateSortDesc() {},
  },
}
</script>